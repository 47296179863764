import restApiAuthorized from '@store/api/restApiAuthorized';
import { getPlatformBalance, getProfileActivityHistory, getRealtimeSummaryBalance } from './mocks';
const userApi = restApiAuthorized.injectEndpoints({
    endpoints: (builder) => ({
        getMe: builder.query({
            query: () => ({
                url: '/api/v1/user/me',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            providesTags: ['Me'],
        }),
        getPlatformBalance: builder.query({
            query: () => ({
                url: '/api/v1/user/me',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            transformResponse: () => getPlatformBalance(),
        }),
        getRealtimeSummaryBalance: builder.query({
            query: () => ({
                url: '/api/v1/user/me',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            transformResponse: () => getRealtimeSummaryBalance(),
        }),
        getProfileActivity: builder.query({
            query: () => ({
                url: '/api/v1/user/me',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            transformResponse: () => getProfileActivityHistory(),
        }),
        deleteUser: builder.mutation({
            query: () => ({
                url: '/api/v1/user',
                method: 'DELETE',
            }),
        }),
    }),
});
export default userApi;
export const { useGetMeQuery, useLazyGetMeQuery, useGetPlatformBalanceQuery, useGetRealtimeSummaryBalanceQuery, useGetProfileActivityQuery, useLazyGetProfileActivityQuery, useDeleteUserMutation, } = userApi;
