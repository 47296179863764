import restApiAuthorized from '@store/api/restApiAuthorized';
const exchangeApi = restApiAuthorized.injectEndpoints({
    endpoints: (builder) => ({
        getExchangeAccounts: builder.query({
            query: () => ({
                url: '/api/v1/external-finance-account/list',
                method: 'GET',
            }),
            providesTags: ['Exchanges'],
        }),
        getWalletsByExchangeId: builder.query({
            query: ({ externalFinanceAccountId, walletType }) => ({
                url: '/api/v1/external-finance-account/wallet',
                method: 'GET',
                params: {
                    wallet_type: walletType,
                    external_finance_account_id: externalFinanceAccountId,
                },
            }),
            // transformResponse: () => getWallets(),
            providesTags: ['Wallets'],
        }),
        createExchangeAccount: builder.mutation({
            query: ({ api_key, connection_name, secret_key }) => ({
                url: '/api/v1/external-finance-account',
                method: 'POST',
                body: {
                    properties: {
                        api_key,
                        connection_name,
                        secret_key,
                    },
                    type: 'BINANCE',
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            invalidatesTags: ['Exchanges'],
        }),
        updateExchangeAccount: builder.mutation({
            query: ({ accountId, api_key, connection_name, secret_key, }) => ({
                url: `/api/v1/external-finance-account/${accountId}`,
                method: 'PUT',
                body: {
                    properties: {
                        api_key,
                        connection_name,
                        secret_key,
                    },
                    type: 'BINANCE',
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            invalidatesTags: ['Exchanges'],
        }),
        deleteExchangeAccount: builder.mutation({
            query: ({ accountId, }) => ({
                url: `/api/v1/external-finance-account/${accountId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Exchanges'],
        }),
    }),
});
export default exchangeApi;
export const { useGetExchangeAccountsQuery, useLazyGetExchangeAccountsQuery, useCreateExchangeAccountMutation, useDeleteExchangeAccountMutation, useUpdateExchangeAccountMutation, useGetWalletsByExchangeIdQuery, useLazyGetWalletsByExchangeIdQuery, } = exchangeApi;
