const USER_ACTIVITY_MOCK = [
    {
        activityType: 'CLOSE_PORTFOLIO',
        actorId: 2,
        subjectId: '7a5a5714-57c9-47c5-9322-0779fc',
        subjectImage: 'binance',
        timestamp: 1684113618000,
        subjectName: 'Binance Lounge',
    },
    {
        activityType: 'START_FOLLOWING',
        actorId: 2,
        subjectId: '7a5a5714-57c9-47c5-9322-0779fc',
        subjectImage: 'binance',
        timestamp: 1684106418000,
        subjectName: 'Robert Fox',
    },
    {
        activityType: 'CREATE_PORTFOLIO',
        actorId: 2,
        subjectId: '7a5a5714-57c9-47c5-9322-0779fc',
        subjectImage: 'binance',
        timestamp: 1684099218000,
        subjectName: 'Binance Lounge',
    },
    {
        activityType: 'CLOSE_PORTFOLIO',
        actorId: 2,
        subjectId: '7a5a5714-57c9-47c5-9322-0779fc',
        subjectImage: 'binance',
        timestamp: 1684012818400,
        subjectName: 'Binance Main',
    },
    {
        activityType: 'STOP_FOLLOWING',
        actorId: 2,
        subjectId: '7a5a5714-57c9-47c5-9322-0779fc',
        subjectImage: 'binance',
        timestamp: 1684011918000,
        subjectName: 'Marry Monro',
    },
    {
        activityType: 'CLOSE_PORTFOLIO',
        actorId: 2,
        subjectId: '7a5a5714-57c9-47c5-9322-0779fc',
        subjectImage: 'binance',
        timestamp: 1684011738000,
        subjectName: 'Binance Lounge',
    },
    {
        activityType: 'CLOSE_PORTFOLIO',
        actorId: 4,
        subjectId: '7a5a5714-57c9-47c5-9322-0779fc',
        subjectImage: 'binance',
        timestamp: 1684012812000,
        subjectName: 'Binance Main',
    },
];
const PLATFORM_BALANCE = 250;
const REALTIME_SUMMARY_BALANCE = 5347.53;
const getPlatformBalance = () => PLATFORM_BALANCE;
const getRealtimeSummaryBalance = () => REALTIME_SUMMARY_BALANCE;
const getProfileActivityHistory = () => {
    const activity = [...USER_ACTIVITY_MOCK].sort((a, b) => {
        if (a.timestamp < b.timestamp) {
            return 1;
        }
        return -1;
    });
    const groupedActivity = {};
    activity.forEach((activityEvent) => {
        const eventDate = new Date(activityEvent.timestamp);
        const dateKey = `${eventDate.getFullYear()}-${eventDate.getMonth()}-${eventDate.getDate()}`;
        if (typeof groupedActivity[dateKey] === 'undefined') {
            groupedActivity[dateKey] = {
                date: eventDate,
                positions: [activityEvent],
            };
        }
        else {
            groupedActivity[dateKey].positions.push(activityEvent);
        }
    });
    return groupedActivity;
};
export { USER_ACTIVITY_MOCK, PLATFORM_BALANCE, REALTIME_SUMMARY_BALANCE, getPlatformBalance, getRealtimeSummaryBalance, getProfileActivityHistory, };
